<template>
  <div class="d-flex flex-column ml-4">
    <icon-mark
      class="mb-4"
      :icon-name="'i.Account'"
      :mark-icon-name="'i.ArrowUp'"
      mark-color="green"
    />
    <icon-mark
      class="mb-4"
      :icon-name="'i.Promises'"
      :mark-icon-name="'i.AlertOctagon'"
      mark-color="error"
      :mark-size=18
    />
    <icon-mark
      class="mb-4"
      :icon-name="'i.Disputes'"
      :mark-icon-name="'i.CheckedCircle'"
      mark-color="blue"
    />
  </div>

</template>

<script>

export default {
  components: {
    IconMark: () => import('@/components/icon-mark')
  },
  created () {
  },
  async mounted () {
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

<style lang="stylus" scoped></style>
